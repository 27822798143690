import React from 'react';
import * as styles from './index.module.scss';

import Button from '../../components/button';

const ApplyToWork = () => {
    const getInTouchClickHandler = () => {
      window.open('https://jobs.jobvite.com/luckybrand/apply', 'Lucky Beard Website', 'noopener');  
    };

  return (
    <div id="apply-for-your-dream-job" className={`apply ${styles.applyToWork}`}>
      <h2 className={'title-M'}>Not seeing your dream job?</h2>
      <p className={'body-M'}>We’re always on the lookout of exceptional talent to join our team. If you want to join a family of hard working, talented and exceptional humans, and do the best work of your life, feel free to reach out to us.</p>
      <Button
        type="primary"
        text="Get in touch"
        iconPosition="right"
        icon="chevron-right"
        onClick={getInTouchClickHandler}
      />
    </div>
  );
};

export default ApplyToWork;