// extracted by mini-css-extract-plugin
export var places = "index-module--places--y6pp4";
export var shape = "index-module--shape--r-Crj";
export var wrapperContent = "index-module--wrapper-content--uGdvw";
export var containerPlaceNames = "index-module--container-place-names--zl-Dh";
export var row = "index-module--row--Bthbb";
export var placeName = "index-module--place-name---GhPR";
export var dividerLine = "index-module--divider-line--EiWYA";
export var left = "index-module--left--0UVYb";
export var inner = "index-module--inner--guvNe";
export var right = "index-module--right--9eNAj";
export var noMargin = "index-module--no-margin--UEYnU";
export var description = "index-module--description--y7OF5";