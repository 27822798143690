import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as styles from './index.module.scss';
import saFlag from '../../assets/images/flags/sa-flag.svg';
import ireFlag from '../../assets/images/flags/ire-flag.svg';
import ukFlag from '../../assets/images/flags/uk-flag.svg';
import Button from '../../components/button';
import { getAllJobs } from '../../helpers/axios';
import { removeHtmlTags } from '../../helpers/helpers';
import { setJobs } from '../../state/jobs';

const CareerListings = () => {
    const dispatch = useDispatch();
    const [activeParentJobIndex, setActiveParentJobIndex] = useState(-1);
    const [activeChildJobIndex, setActiveChildJobIndex] = useState(-1);

    const toggleParentAccordion = (index) => {
        if(index === activeParentJobIndex) { 
            setActiveParentJobIndex(-1);
            setActiveChildJobIndex(-1);
            return;
        }
        setActiveChildJobIndex(-1);
        setActiveParentJobIndex(index);
    };

    const toggleChildAccordion = (index) => {
        if(index === activeChildJobIndex) return setActiveChildJobIndex(-1);
        setActiveChildJobIndex(index);
    };

    const getFlag = (code) => {
        switch(code) {
            case 'South Africa':
                return saFlag;
            case 'Ireland':
                return ireFlag;
            default:
                return ukFlag;
        }
    };

    const gotoLisiting = (eId) => {
        window.open(`https://jobs.jobvite.com/luckybrand/job/${eId}`, 'Lucky Beard Website', 'noopener');
    };

    const { jobs } = useSelector(
        (state) =>
            state.jobs
    );    

    const fetchJobs = async () => {
        const response = await getAllJobs();
        dispatch(setJobs(response));
    };
    
    useEffect(() => {
        if(jobs === null){
            fetchJobs();
        }
    }, [jobs]);
    
    return (
        <div id={'openings'} className={`${styles.careerListingWrapper}`}>
            <div className={`${styles.careerListingContainer}`}>
                <div className={`${styles.careerListingHeading}`}>
                    <h2 className={'title-M'}>Careers at Lucky Beard</h2>
                    <p className={'body-M'}>Want to be exceptional every day? Then come work with us.</p>
                </div>

                <div className={`${styles.careerListingAccordionContainer}`}>
                    {jobs && jobs?.map((location, locactionIndex) => (
                        <div key={location.name} className={`${styles.careerListingAccordionItem} ${location.jobs.length === 0 ? styles.disabled : styles.active}`}>

                            <div onClick={location.jobs.length > 0 ? () => {toggleParentAccordion(locactionIndex); } : null} className={`${styles.careerListingAccordionItemQuestion} ${styles.parent}`}>
                                <img src={getFlag(location.name)} alt={location.name} />
                                <p className={'title-S'}>{`${location.name} (${location.jobs.length})`}</p>
                                {location.jobs.length > 0 && (
                                    <>
                                        <div className={`${styles.careerListingAccordionItemQuestionIcon} ${activeParentJobIndex === locactionIndex ? styles.expand : styles.collapse}`}>
                                            <div className={`${styles.accordionIcon} ${styles.shape1}`}></div>
                                            <div className={`${styles.accordionIcon} ${styles.shape2}`}></div>
                                        </div>
                                    </>
                                )}                                
                            </div>

                            <div className={` ${styles.careerListingAccordionItemAnswer} ${styles.child} ${activeParentJobIndex === locactionIndex ? styles.expand : styles.collapse}`}>
                                {location?.jobs?.map((job, jobIndex) => (
                                    <div key={`${location.name}-${job.name}`} className={`${styles.careerListingAccordionItem}`}>
                                        
                                        <div onClick={() => { toggleChildAccordion(jobIndex); }} className={`${styles.careerListingAccordionItemQuestion} ${styles.child}`}>
                                            <p className={'body-L'}>{job.title}</p>
                                            <div className={`${styles.careerListingAccordionItemQuestionIcon} ${activeChildJobIndex === jobIndex ? styles.expand : styles.collapse}`}>
                                                <div className={`${styles.accordionIcon} ${styles.shape1}`}></div>
                                                <div className={`${styles.accordionIcon} ${styles.shape2}`}></div>
                                            </div>
                                        </div>
                                        
                                        <div className={`${styles.careerListingAccordionItemAnswer} ${activeChildJobIndex === jobIndex ? styles.expand : styles.collapse}`}>
                                            <div className={`${styles.careerListingAccordionItemAnswerBody}`}>

                                                <p className={'body-M'}>{removeHtmlTags(job.briefDescription)}</p>
                                                
                                                <Button
                                                    type="primary"
                                                    text="Learn more"
                                                    iconPosition="right"
                                                    icon="chevron-right"
                                                    onClick={() => { gotoLisiting(job.eId); }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                ))}                                
                            </div>

                        </div>
                    ))}
                </div>
            </div>

            <Button
                type="ghost"
                text="Not seeing your dream Job?"
                iconPosition="right"
                icon="chevron-right-dark"
                onClick={() => {
                    window.location.href = '#apply-for-your-dream-job';
                }}
            />
        </div>
    );
};

export default CareerListings;