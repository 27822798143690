import React, { useRef, useEffect, useState } from 'react';
import * as styles from './index.module.scss';
import ScrollablePage from '../../layouts/scrollable-page';
import useImagePreloader from '../../hooks/useImagePreloader';

import SEO from '../../components/seo';
import Preloader from '../../components/preloader';
import ScrollablePageHeader from '../../components/scrollable-page-header';
import WhatDrivesUs from '../../components/what-drives-us';
import WhatUnitesUs from '../../components/what-unites-us';
import Languages from '../../components/languages';
import Places from '../../components/places';
import WorkWithUs from '../../components/work-with-us';
import ApplyToWork from '../../components/apply-to-work';
import HiringProcess from '../../components/hiring-process';
import InstagramCarousel from '../../components/instagram-carousel';
import CareerListings from '../../components/career-listings';
import FAQ from '../../components/faq';
import Footer from '../../components/footer';
// import AspectRatioContainer from '../../components/aspect-ratio-container';
import cube from '../../assets/images/shapes/topdown-renders/cube.png';
import dollos from '../../assets/images/shapes/topdown-renders/dollos.png';
import cone from '../../assets/images/shapes/topdown-renders/cone.png';
import tube from '../../assets/images/shapes/topdown-renders/tube.png';
import { scrollableHeaderLandingAnimation } from '../../animations/hero';

import {
  animateHeadline,
  animateWhatUnitesUs,
  animateLanguages,
  animatePlaces,
  animateOurHiringProcess,
  animateApply,
  // animateFooterMural,
} from '../../animations/careers';
import ScrollTrigger from 'gsap/ScrollTrigger';


const WhatWeDo = () => {
  const scrollablePageRef = useRef();
  const rootRef = useRef();
  const imagePreloader = useImagePreloader([cube, dollos, cone, tube]);

  useEffect(() => {
    imagePreloader.startPreloading();
  }, []);

  useEffect(() => {
    if (imagePreloader.isLoading) return;
    scrollableHeaderLandingAnimation(rootRef, () => {
      // console.log('Cleaning up st');
    });
    
    animateHeadline(rootRef, scrollablePageRef);
    animateWhatUnitesUs(rootRef, scrollablePageRef);
    animateLanguages(rootRef, scrollablePageRef);
    animatePlaces(rootRef, scrollablePageRef);
    animateOurHiringProcess(rootRef, scrollablePageRef);
    animateApply(rootRef, scrollablePageRef);
    // animateFooterMural(rootRef, scrollablePageRef);
    ScrollTrigger.refresh();
  }, [imagePreloader.isLoading]);
  
  return (
    <ScrollablePage simpleBarRef={scrollablePageRef}>
      <SEO
        keywords={'UX design jobs, UI design jobs, Copywriting jobs'}
        preventIndexing={false}
        shareImage={''}
        description={
          'Work with us. We work with exceptional people to make things that change things. UX design jobs, UI design jobs, copywriting jobs in Dublin, London and South Africa'
        }
        title={'Lucky Beard | Design and Advisory Careers | Join Us'}
      />

      {imagePreloader.isLoading && <Preloader />}

      <div ref={rootRef} className={`${styles.careersWrapper}`}>
        <ScrollablePageHeader
          scrollablePageRef={scrollablePageRef}
          subHeading={''}
          heading={`Life at
            Lucky Beard`}
          description={
            'Our company is made up of a diverse bunch of exceptional humans with refreshing perspectives, deep expertise and unconventional wisdom.'
          }
          ctaLabel={'Apply'}
          ctaLink={'#openings'}
          // description2={
          //   'Meet the exceptional beards who come to work every day, to make things that change things.'
          // }
          images={{ shape1: cube, shape2: dollos, shape3: cone }}
        />

        {/* Will add back when fix has been deployed: https://github.com/webvibe-io/strapi-plugin-instagram/pull/21 */}
        {/* <InstagramCarousel /> */}

        <WhatDrivesUs />

        <WhatUnitesUs />

        <Languages />

        <Places />

        <CareerListings />

        <HiringProcess shape={cone} />

        <ApplyToWork />

        <WorkWithUs shape={tube} hideCTA={true} bgColor={'#f9f9fa'} />

        <FAQ />

        {/* <AspectRatioContainer aspectRatio={'16/9'}>
          <div className={`fm ${styles.footerMural}`} />
        </AspectRatioContainer> */}

        <Footer />
      </div>
    </ScrollablePage>
  );
};

export default WhatWeDo;
