// extracted by mini-css-extract-plugin
export var hiringProcess = "index-module--hiring-process--HLI72";
export var hiringProcessContentBackgroundShape = "index-module--hiring-process-content-background-shape--AFGyl";
export var shape1 = "index-module--shape1--ZiFr7";
export var shape2 = "index-module--shape2--MrALG";
export var shape3 = "index-module--shape3--PzUuI";
export var hiringProcessContentMain = "index-module--hiring-process-content-main--c5Car";
export var hiringProcessContentMainStep = "index-module--hiring-process-content-main-step--v2Phm";
export var hiringProcessContentMainStepEmptySpace = "index-module--hiring-process-content-main-step-empty-space--nSfvW";
export var hiringProcessContentMainStepNumber = "index-module--hiring-process-content-main-step-number--Wkl9S";
export var hiringProcessContentMainBody = "index-module--hiring-process-content-main-body--Jkr8M";
export var hiringProcessContentMainBodyNavigation = "index-module--hiring-process-content-main-body-navigation--phHhx";
export var hiringProcessContentMainBodyNavigationNext = "index-module--hiring-process-content-main-body-navigation-next--S5yGE";
export var hiringProcessContentMainBodyNavigationPrev = "index-module--hiring-process-content-main-body-navigation-prev--V9jJM";
export var hiringProcessContentSteps = "index-module--hiring-process-content-steps--fP+6o";
export var hiringProcessStepsLine = "index-module--hiring-process-steps-line--ZrOoU";
export var hiringProcessStepsCircle = "index-module--hiring-process-steps-circle--PqMMx";
export var hiringProcessStepsCircleActive = "index-module--hiring-process-steps-circle-active--BcE7L";