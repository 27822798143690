import React from 'react';
import * as styles from './value.module.scss';

const Value = ({data}) => {
    return (
        <div className={`${styles.value}`}>
            <h4 className={'copy body-XS'}>{data.title}</h4>
            <p className={'copy body-S'}>{data.copy}</p>
        </div>
    );
};

export default Value;