import React from 'react';
import Slogan from '../slogan';
import * as styles from './index.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import tube from '../../assets/images/shapes/topdown-renders/tube.png';
import Squiggly from '../squiggly';

const Languages = () => {
  const languages = [
    'ENGLISH',
    'AFRIKAANS',
    'GAEILGE',
    'SESOTHO',
    'SETSWANA',
    'FRENCH',
    'HINDI',
    'JEG LAERER',
    'SPANISH',
    'HEBREW',
    'PORTUGUESE',
    'ISIXHOSA',
    'ITALIAN',
    'SEPEDI',
    'GERMAN',
    'ISIZULU',
    'PUNJABI',
    'JAVASCRIPT',
    'HTML'
  ];
  return (
    <div className={` langs ${styles.languages}`}>
      <StaticImage alt="A topdown render of a tube shape" src={'../../assets/images/shapes/topdown-renders/tube.png'} className={`shape ${styles.shape}`} />

      <div className={`${styles.wrapperContent}`}>

        <div className={`${styles.containerTop}`}>
          <h1 className={`total ${styles.giantNumber}`}>19</h1>
          <p className={'other body-L'}>We speak over 19 languages but we're all fluent in Beard</p>
          <div className={`other ${styles.sloganWrapper}`}>
              <Slogan width={120} spin={true} />
          </div>
        </div>
        <div className={`${styles.containerBottom}`}>
          <div className={`${styles.containerLanguages}`}>
            {languages.length > 0 && languages.map((item, index) => (
              <div className={`other utility-overline-light ${styles.language}`} key={index + '-' + item}>{item}</div>
            ))}
          </div>

          <div className={`other ${styles.squigglyWrapper}`}>
            <Squiggly />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Languages;