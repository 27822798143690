import gsap, { Power4, Back } from 'gsap';
import { isMobile } from 'react-device-detect';
const animateHeadline = (node, scrollBarRef) => {
  if (!node || !node.current) return;
  const duration = 1;
  const easing = Power4.easeOut;
  // here we define a timeline with one scrolltrigger, that triggers multiple tweens for copy and images respectively
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: node.current.querySelector('.wdu'),
      start: `top ${isMobile ? '70%' : '40%'}`,
      scroller: scrollBarRef.current,
      // markers:true
    },
  });

  const tHeadline = gsap.from(node.current.querySelectorAll('.wdu .copy'), {
    duration,
    ease: easing,
    y: 20,
    stagger: 0.2,
    opacity: 0,
  });

  const tShape = gsap.from(node.current.querySelector('.wdu .shape'), {
    duration,
    ease: easing,
    opacity: 0,
    x: 100,
    scale: 0,
  });

  tl.add(tHeadline, 0);
  tl.add(tShape, 0.2);
};

const animateWhatUnitesUs = (node, scrollBarRef) => {
  if (!node || !node.current) return;

  const duration = 1;
  const easing = Power4.easeOut;
  // here we define a timeline with one scrolltrigger, that triggers multiple tweens for copy and images respectively
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: node.current.querySelector('.wuu'),
      start: `top ${isMobile ? '70%' : '40%'}`,
      scroller: scrollBarRef.current,
      // markers:true
    },
  });

  const tCopy = gsap.from(node.current.querySelectorAll('.wuu .copy'), {
    duration,
    ease: easing,
    y: 15,
    stagger: 0.03,
    opacity: 0,
  });

  tl.add(tCopy, 0);
};

const animateLanguages = (node, scrollBarRef) => {
  if (!node || !node.current) return;

  const duration = 1;
  const easing = Power4.easeOut;
  // here we define a timeline with one scrolltrigger, that triggers multiple tweens for copy and images respectively
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: node.current.querySelector('.langs'),
      start: `top ${isMobile ? '70%' : '40%'}`,
      scroller: scrollBarRef.current,
      // markers:true
    },
  });

  const tTotal = gsap.from(node.current.querySelector('.langs .total'), {
    duration: duration,
    ease: easing,
    scale: 0.95,
    rotate: -5,
    // stagger: 0.03,
    opacity: 0,
  });

  const tOther = gsap.from(node.current.querySelectorAll('.langs .other'), {
    duration: duration,
    ease: easing,
    stagger: 0.03,
    opacity: 0,
  });

  const tShape = gsap.from(node.current.querySelector('.langs .shape'), {
    duration: duration,
    ease: easing,
    x: -100,
    opacity: 0,
    rotate: -35,
  });

  tl.add(tTotal, 0);
  tl.add(tOther, 0.5);
  tl.add(tShape, 0.8);
};

const animatePlaces = (node, scrollBarRef) => {
  if (!node || !node.current) return;

  const duration = 1;
  const easing = Power4.easeOut;
  // here we define a timeline with one scrolltrigger, that triggers multiple tweens for copy and images respectively

  const tPlaces = gsap.from(node.current.querySelectorAll('.names-1 .row'), {
    duration: duration * 1,
    ease: easing,
    stagger: 0.02,
    width: '150vw',
    marginLeft: '-50vw',
    opacity: 0,
    scrollTrigger: {
      trigger: node.current.querySelector('.names-1'),
      start: `top ${isMobile ? '70%' : '40%'}`,
      scroller: scrollBarRef.current,
      // markers:true
    },
  });

  const tConvergence = gsap.from(
    node.current.querySelectorAll('.places .convergence'),
    {
      duration: duration * 1,
      ease: easing,
      x: -10,
      opacity: 0,
      scrollTrigger: {
        trigger: node.current.querySelector('.convergence'),
        start: `top ${isMobile ? '70%' : '40%'}`,
        scroller: scrollBarRef.current,
        // markers:true
      },
    }
  );

  const tPlaces2 = gsap.from(node.current.querySelectorAll('.names-2 .row'), {
    duration: duration * 1,
    ease: easing,
    stagger: 0.02,
    width: '150vw',
    marginLeft: '-50vw',
    opacity: 0,
    scrollTrigger: {
      trigger: node.current.querySelector('.names-2'),
      start: `top ${isMobile ? '70%' : '40%'}`,
      scroller: scrollBarRef.current,
      // markers:true
    },
  });
};

const animateOurHiringProcess = (node, scrollBarRef) => {
  if (!node || !node.current) return;

  const duration = 1;
  const easing = Power4.easeOut;
  // here we define a timeline with one scrolltrigger, that triggers multiple tweens for copy and images respectively

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: node.current.querySelector('.hiring'),
      start: `top ${isMobile ? '70%' : '40%'}`,
      scroller: scrollBarRef.current,
      // markers:true
    },
  });

  const sTitle = gsap.from(node.current.querySelectorAll('.hiring .headline'), {
    duration: duration,
    ease: easing,
    opacity: 0,
    y: -20,
  });

  const sContent = gsap.from(
    node.current.querySelectorAll('.hiring .hc-content'),
    {
      duration: duration,
      ease: easing,
      stagger: 0.05,
      opacity: 0,
      y: -20,
    }
  );

  const sLines = gsap.from(node.current.querySelectorAll('.hiring .line'), {
    duration: duration,
    ease: easing,
    stagger: 0.1,
    width: 0,
  });

  const sBalls = gsap.from(node.current.querySelectorAll('.hiring .ball'), {
    duration: duration,
    ease: Back.easeOut,
    scale: 1.5,
    opacity: 0,
    stagger: 0.1,
  });

  tl.add(sTitle, 0);
  tl.add(sLines, 0.2);
  tl.add(sBalls, 0.3);
  tl.add(sContent, 0.4);
};

const animateApply = (node, scrollBarRef) => {
  if (!node || !node.current) return;

  const duration = 1;
  const easing = Power4.easeOut;
  // here we define a timeline with one scrolltrigger, that triggers multiple tweens for copy and images respectively

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: node.current.querySelector('.apply'),
      start: `top ${isMobile ? '70%' : '40%'}`,
      scroller: scrollBarRef.current,
      // markers:true
    },
  });

  const tFrame = gsap.from(node.current.querySelector('.apply'), {
    borderColor: 'rgba(118,116,126,0)',
    duration,
    ease: easing,
  });

  const tCopy = gsap.from(node.current.querySelectorAll('.apply>*'), {
    opacity: 0,
    stagger: 0.1,
    y: 10,
    duration: duration,
    ease: easing,
  });

  tl.add(tFrame, 0);
  tl.add(tCopy, 0);
};

const animateFooterMural = (node, scrollBarRef) => {
  if (!node || !node.current) return;

  const duration = 1;
  const easing = Power4.easeOut;
  // here we define a timeline with one scrolltrigger, that triggers multiple tweens for copy and images respectively

  const tFooter = gsap.to(node.current.querySelector('.fm'), {
    scrollTrigger: {
        trigger: node.current.querySelector('.fm'),
        start: 'top 0',
        scroller: scrollBarRef.current,
        scrub:true,
        // markers:true,
        
      },
      backgroundPositionY:100,
      scale:1.1,
      rotate:-2,
      duration,
      ease:easing,
    //   height: 1000
  });
};

export {
  animateHeadline,
  animateWhatUnitesUs,
  animateLanguages,
  animatePlaces,
  animateOurHiringProcess,
  animateApply,
  animateFooterMural,
};
