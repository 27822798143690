import React from 'react';
import * as styles from './index.module.scss';
import Value from './value';

const WhatUnitesUs = () => {

    const values = [
        {
            title: 'Be a good human',
            copy: "It's as simple as that. Respect and kindness are non-negotiable. Life is too short for it to be any other way."
        },
        {
            title: 'Get involved',
            copy: "No one stands on the sidelines, we all get stuck in. It's the only way to get things done. We wouldn't want it any other way."
        },
        {
            title: 'Never settle',
            copy: 'Complacency is our sworn enemy. Everything can and should be improved, and then improved again after that.'
        },
        {
            title: 'Give a damn',
            copy: "We don't have lots of people, we have special people, who care deeply about what they do and who they do it with."
        },
        {
            title: 'Find a way',
            copy: 'There will never not be knots, roadblocks and obstacles. There will also never not be solutions if you make the effort to look.'
        },
        {
            title: 'Make it simpler',
            copy: 'The world is complicated enough already. We keep things simple, we make things clear and we say it as it is.'
        }
    ];

    return (
        <div className={`wuu ${styles.whatUnitesUs}`}>
            <h4 className={'copy utility-overline-heavy'}>What unites us</h4>
            <h2 className={'copy title-M'}>Our values & behaviours</h2>

            {values.length > 0 && <div className={`${styles.valuesContainer}`}>
                {values.map((item, index)=>
                    <Value key={index} data={item} />
                )}
            </div>}
        </div>
    );
};

export default WhatUnitesUs;