import React from 'react';
import Slogan from '../slogan';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './index.module.scss';

// import torus from '';

const Places = () => {
  const topPlaces = [
    ['Aberdeen', 'Benoni'],
    ['Bergerac', 'Bethlehem'],
    ['Bloemhof', 'Boston'],
    ['Cape Town', 'Centurion'],
    ['Cheam', 'Clonakilty'],
    ['Drogheda', 'Dublin'],
    ['Edenvale', 'Epsom'],
    ['Hoedspruit'],
    ['','Johannesburg'],
    ['Klerksdorp', 'Perth'],
    ['Kempton Park', 'Durban'],
  ];

  const bottomPlaces = [
    ['KwaMlanga', 'Madrid'],
    ['Midrand', 'Omagh'],
    ['Phalaborwa'],
    ['','Pietermaritzburg'],
    ['Pretoria', 'Roodepoort'],
    ['Perth', 'Rustenburg'],
    ['Sala Consilina', 'Sandton'],
    ['Sebokeng', 'Soweto'],
    ['Vryheid', 'Welkom'],
    ['Witbank'],
  ];

  return (
    <div className={`places ${styles.places}`}>
      <div className={`${styles.wrapperContent}`}>
        <StaticImage alt="A topdown render of a torus shape" src={'../../assets/images/shapes/topdown-renders/torus.png'} className={`${styles.shape}`} />

        <div className={`names-1 ${styles.containerPlaceNames}`}>
          {topPlaces.length > 0 &&
            topPlaces.map((place, i) => (
              <div key={place} className={`row ${styles.row}`}>
                <span className={`${styles.placeName} `}>{place[0]} </span>

                <div className={`${styles.dividerLine}`}>
                  <div className={styles.inner} />
                </div>

                {place[1] ? (
                  <span className={`${styles.placeName} ${styles.right}`}>
                    {place[1]}
                  </span>
                ) : null}
              </div>
            ))}
        </div>

        <p className={`convergence body-L ${styles.description}`}>
          Our company is the convergence of a diverse bunch of exceptional
          humans with refreshing perspectives, deep expertise and unconventional
          wisdom.
        </p>

        <div className={`names-2 ${styles.containerPlaceNames}`}>
          {bottomPlaces.length > 0 &&
            bottomPlaces.map((place, i) => (
              <div key={place} className={`row ${styles.row}`}>
                <span className={`${styles.placeName} `}>{place[0]} </span>

                <div className={`${styles.dividerLine}`}>
                  <div className={styles.inner} />
                </div>

                {place[1] ? (
                  <span className={`${styles.placeName} ${styles.right}`}>
                    {place[1]}
                  </span>
                ) : null}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Places;
