import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as styles from './index.module.scss';
import { getFAQs } from '../../helpers/axios';
import { setFAQs } from '../../state/faqs';

const FAQ = () => {
    const dispatch = useDispatch();    
    const [activeFAQIndex, setActiveFAQIndex] = useState(-1);

    const expandAccordion = (index) => {
        if(index === activeFAQIndex) return setActiveFAQIndex(-1);
        setActiveFAQIndex(index);
    };

    const faqData = useSelector(
        (state) =>
            state.faqs
    );    

    const fetchFAQs = async () => {
        const response = await getFAQs();
        dispatch(setFAQs(response.data));
    };
    
      useEffect(() => {
        if(faqData.faqs == null){
            fetchFAQs();
        }
      }, [faqData]);

    return (
        <div className={`${styles.faqWrapper}`}>
            <h2 className={`title-XL ${styles.faqHeading}`}>FAQ</h2>

            <div className={`${styles.faqAccordionContainer}`}>
                {faqData?.faqs && faqData?.faqs?.map((faq, i) => (
                    <div key={faq?.attributes?.question} className={`${styles.faqAccordionItem}`}>
                        <div onClick={() => { expandAccordion(i); }} className={`${styles.faqAccordionItemQuestion}`}>
                            <p className={'title-XS semi-bold'}>{faq?.attributes?.question}</p>
                            <div className={`${styles.faqAccordionItemQuestionIcon} ${activeFAQIndex === i ? styles.expand : styles.collapse}`}>
                                <span className={'icon-chevron-down'}></span>
                            </div>
                        </div>
                        <div className={`${styles.faqAccordionItemAnswer} ${activeFAQIndex === i ? styles.expand : styles.collapse}`}>
                            <div className={`${styles.faqAccordionItemAnswerBody}`}>
                                <p className={'body-S'}>{faq?.attributes?.answer}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;