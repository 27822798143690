import React, { useState } from 'react';
import arrowLeft from '../../assets/images/arrow-left-icon.svg';
import * as styles from './index.module.scss';

const HiringProcess = ({ shape }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const hiringProcessSteps = [
    {
      title: 'Meet the team',
      copy: 'Meet HR and the relevant discipline leads.',
    },
    {
      title: 'Get a task',
      copy: 'An opportunity for you to show us what you can do.',
    },
    {
      title: 'Present your task',
      copy: 'Present back to the relevant leads and exco members.',
    },
    {
      title: 'The offer',
      copy: 'Contracts and starting dates all ironed out.',
    },
    {
      title: 'Onbearding',
      copy: "Welcome onbeard. Let's show you around and get you settled in!",
    },
    {
      title: 'You are Beard now.',
      copy: 'Start making things that change things',
    },
  ];

  const nextStep = () => {
    setActiveStepIndex(activeStepIndex === (hiringProcessSteps.length - 1) ? 0 : activeStepIndex + 1);
  };

  const prevStep = () => {
    setActiveStepIndex(activeStepIndex === 0 ? (hiringProcessSteps.length -1) : activeStepIndex - 1);
  };

  return (
    <div className={`hiring ${styles.hiringProcess}`}>
      <h1 className={'headline utility-noS'}>Our hiring process</h1>

      <img className={`${styles.hiringProcessContentBackgroundShape} ${styles.shape1}`} src={shape} alt={'Hiring process background shape'} />
      <img className={`${styles.hiringProcessContentBackgroundShape} ${styles.shape2}`} src={shape} alt={'Hiring process background shape'} />
      <img className={`${styles.hiringProcessContentBackgroundShape} ${styles.shape3}`} src={shape} alt={'Hiring process background shape'} />


      <div className={`${styles.hiringProcessContentWrapper}`}>
        <div className={`${styles.hiringProcessContentMain}`}>
          <p className={`title-S ${styles.hiringProcessContentMainStep}`}>Step</p> 

          <div className={`${styles.hiringProcessContentMainStepEmptySpace}`}></div>

          <div className={`${styles.hiringProcessContentMainStepNumber}`}>{activeStepIndex + 1}</div>

          <div className={`${styles.hiringProcessContentMainBody}`}>
            <p className={'title-S semi-bold'}>{hiringProcessSteps[activeStepIndex].title}</p>
            <p className={'body-S'}>{hiringProcessSteps[activeStepIndex].copy}</p>
            <div className={`${styles.hiringProcessContentMainBodyNavigation}`}>
              <img className={`${styles.hiringProcessContentMainBodyNavigationNext}`} alt={'Hiring process previous step'} src={arrowLeft} onClick={prevStep} />              
              <img className={`${styles.hiringProcessContentMainBodyNavigationPrev}`} alt={'Hiring process next step'} src={arrowLeft} onClick={nextStep} />
            </div>
          </div>
        </div>

        <div className={`${styles.hiringProcessContentSteps}`}>
          {hiringProcessSteps.map((step, i) => {
            return (
              <>
                <div className={`${styles.hiringProcessStepsLine}`}></div>              
                <div className={`${styles.hiringProcessStepsCircle} ${activeStepIndex === i ? styles.hiringProcessStepsCircleActive : ''}`}></div>
              </>
            );
          })}
          <div className={`${styles.hiringProcessStepsLine}`}></div> 
        </div>
      </div>
    </div>
  );
};

export default HiringProcess;
