import React from 'react';
import * as styles from './index.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
// import sphere from ''

const WhatDrivesUs = () => {
    return (
        <div className={`wdu ${styles.whatDrivesUs}`}>
            <StaticImage alt="a top down render of a sphere shape" src={'../../assets/images/shapes/topdown-renders/sphere.png'} className={`shape ${styles.shape}`} />

            <div className={`${styles.wrapperContent}`}>
                <h4 className={'copy utility-overline-heavy'}>What drives us</h4>
                <h1 className={'copy title-XL'}>Our mission is to create a home where exceptional talent can make a meaningful difference </h1>
            </div>
                
        </div>
    );
};

export default WhatDrivesUs;